import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { FaTimes } from 'react-icons/fa'

import { makeStyles } from '@material-ui/core/styles'
import PublishedCard from '../publish/PublishedCard'
import AuthorCard from './AuthorCard'

const useStyles = makeStyles(() => ({
    modalStyle: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // minWidth: '45vw',
        // minHeight: '60vh',
        margin: 'auto',
        padding: '10px',
        backgroundColor: 'white',
        zIndex: 100,
        borderRadius: '12px',
        opacity: 1,
        border: '1px solid #666',
        overflowY: 'initial',
    },
    modalTitle: {
        textAlign: 'center',
        marginTop: '2%',
    },
    grayBackground: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        opacity: 0.9,
        zIndex: 90,
    },
    modalClose: {
        position: 'absolute',
        minWidth: '2px',
        minHeight: '2px',
        top: 5,
        right: 5,
    },
    modalBody: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxHeight: '50vh',
        overflowY: 'auto',
        marginTop: '2%',
    },
    authors: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}))

const ProfileModal = ({
    titleTextPM,
    displayStatusPM,
    setDisplayStatusPM,
    publicProfile,
    authors,
}) => {
    const classes = useStyles()

    const likedText = (
        <strong>Stories {publicProfile?.displayName} likes</strong>
    )

    const followedText = (
        <strong>Writers {publicProfile?.displayName} follows</strong>
    )

    return (
        <>
            <Box
                className={classes.grayBackground}
                display={displayStatusPM}
            ></Box>
            <Box className={classes.modalStyle} display={displayStatusPM}>
                <Box>
                    <Button
                        className={classes.modalClose}
                        onClick={() => setDisplayStatusPM('none')}
                    >
                        <FaTimes />
                    </Button>
                </Box>
                <Box>
                    <Typography
                        className={classes.modalTitle}
                        variant="h6"
                        component="h2"
                    >
                        {titleTextPM === 'followedText' && followedText}
                        {titleTextPM === 'likedText' && likedText}
                    </Typography>
                </Box>
                <Box
                    className={`${classes.modalBody} ${
                        authors ? classes.authors : ''
                    }`}
                >
                    {titleTextPM === 'likedText' &&
                        publicProfile.likedPublishesDetails?.map(
                            (published) => (
                                <PublishedCard
                                    published={published.publishedData}
                                    key={published.uid}
                                ></PublishedCard>
                            )
                        )}
                    {titleTextPM === 'followedText' &&
                        publicProfile.followingProfileDetails?.map(
                            (profile) => (
                                <AuthorCard
                                    profile={profile.profileData}
                                    key={profile.uid}
                                    handleClose={() =>
                                        setDisplayStatusPM('none')
                                    }
                                ></AuthorCard>
                            )
                        )}
                </Box>
            </Box>
        </>
    )
}

export default ProfileModal
