import {
    Container,
    Grid,
    Typography,
    CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FaImage } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
    saveFeaturedImage,
    savePublishedFeaturedImage,
} from '../../actions/write'

const useStyles = makeStyles((theme) => ({
    dropbox: {
        border: '1px solid #ccc',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: 'white',
        textAlign: 'center',
        borderRadius: '4px',
    },
    message: {
        color: '#666',
        marginTop: theme.spacing(2),
    },
    featuredPreview: {
        width: '100%',
        maxHeight: 200,
        objectFit: 'contain',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    previewContainer: {
        maxHeight: 200,
        marginBottom: theme.spacing(2),
    },
    featuredIcon: {
        position: 'relative',
        top: 10,
    },
    featuredBox: {},
}))

function FeaturedPicker({
    projectId,
    publishedId,
    sectionId,
    featuredImageList,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const project = useSelector((state) => state.write.project)
    const published = useSelector((state) => state.write.published)
    const section = project.sections.filter((s) => s.id === sectionId)
    const featuredUploading = useSelector(
        (state) => state.write.featuredUploading
    )

    const currentObj = useCallback(() => {
        if (projectId && !sectionId) {
            return project
        } else if (sectionId) {
            return section
        } else if (publishedId) {
            return published
        }
    }, [projectId, sectionId, published])()

    console.log('AVAILABLE SECTION:: ', currentObj)
    // useEffect(() => {
    //     console.log('FEATURED::', currentObj.featuredUrls)
    // }, [currentObj])
    // console.log('CURENT:: ', currentObj)

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                //console.log(acceptedFiles)
                if (projectId && !sectionId) {
                    dispatch(
                        saveFeaturedImage(projectId, null, acceptedFiles[0])
                    )
                } else if (sectionId) {
                    dispatch(
                        saveFeaturedImage(
                            projectId,
                            sectionId,
                            acceptedFiles[0]
                        )
                    )
                } else if (publishedId) {
                    dispatch(
                        savePublishedFeaturedImage(
                            publishedId,
                            acceptedFiles[0]
                        )
                    )
                }
            }
        },
        [dispatch, projectId, publishedId, sectionId]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/webp',
    })

    return (
        <Container className={classes.dropbox} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <>
                    {featuredUploading && <CircularProgress />}
                    {!featuredUploading && currentObj.featuredUrls && (
                        <Grid
                            container
                            className={classes.previewContainer}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img
                                alt="Featured preview"
                                className={classes.featuredPreview}
                                src={currentObj.featuredUrls.featuredLarge}
                            />
                        </Grid>
                    )}

                    {!featuredUploading && (
                        <Container className={classes.featuredBox}>
                            <FaImage
                                className={classes.featuredIcon}
                                size="2em"
                                color="#666"
                            />
                            Select featured image
                        </Container>
                    )}
                    <Typography
                        className={classes.message}
                        variant="body2"
                    ></Typography>
                </>
            )}
        </Container>
    )
}

export default FeaturedPicker
