import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import ReactDOM from 'react-dom'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Alert from '@material-ui/lab/Alert'
import InputLabel from '@material-ui/core/InputLabel'
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline'
import React, { useEffect, useState } from 'react'
import {
    FaLayerGroup,
    FaLink,
    FaRegFileAlt,
    FaSync,
    FaTimesCircle,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { publishDirect, unPublishDirect } from '../../actions/write'
import { slugUrl } from '../../shared/slugUrl'
import FeaturedPicker from '../project/FeaturedPicker'

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    updateMessage: {
        margin: theme.spacing(1),
    },
    publishBox: {
        border: '1px solid #ccc',
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: theme.breakpoints.values.sm,
    },
    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    publishButton: {
        marginRight: 10,
    },
    publishDescription: {
        marginBottom: 15,
        textAlign: 'center',
        color: '#666',
    },

    publishSlug: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: 'white',
        width: '100%',
    },
    copyButton: {
        marginTop: 14,
        marginLeft: -50,
    },
    publishActions: {
        textAlign: 'center',
        padding: 0,
        marginLeft: 0,
        marginRight: 0,
    },
    publishVisibility: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    visibilityInput: {
        width: '100%',
        marginTop: theme.spacing(2),
        textAlign: 'left',
        backgroundColor: 'white',
    },
    publishedMessage: {
        color: '#666',
        marginBottom: theme.spacing(1),
    },
    communityInput: {
        width: '100%',
    },
    publishedDate: {
        marginBottom: theme.spacing(1),
        color: '#666',
    },
    parentGroup: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: 5,
        maxWidth: theme.breakpoints.values.sm,
        marginBottom: 10,
    },
    publishSections: {
        padding: 5,
        maxWidth: theme.breakpoints.values.sm,
        marginBottom: theme.spacing(2),
        marginLeft: 0,
        marginRight: 0,
    },

    parentLabel: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    root: {
        position: 'relative',
        marginTop: '8px',
    },
    contentWrapper: {
        position: 'relative',
    },
    content: {
        // padding: '18.5px 14px',
        borderRadius: '4px',
    },
    inputLabel: {
        position: 'absolute',
        left: 0,
        top: 0,
        // slight alteration to spec spacing to match visual spec result
        transform: 'translate(0, 24px) scale(1)',
        fontSize: '17px',
        opacity: 0.9,
    },
    notchedOutline: {},
}))
function compare(a, b) {
    let comparison = 0
    if (a.position > b.position) {
        comparison = 1
    } else if (a.position < b.position) {
        comparison = -1
    }
    return comparison
}

const isSelected = (sections) => {
    for (const [key, value] of Object.entries(sections)) {
        if (value) return true
    }
    return false
}
export default function PublishDirect({ setSections }) {
    const dispatch = useDispatch()

    const project = useSelector((state) => state.write.project)
    const current = useSelector((state) => state.ui.sidebarPosition)
    const profile = useSelector((state) => state.profile.profile)

    const featuredUploading = useSelector(
        (state) => state.write.featuredUploading
    )
    const projectUpdating = useSelector((state) => state.write.projectUpdating)

    const isOverview = current === -1

    const currentSection = project.sections.filter(
        (s) => s.position === current
    )[0]
    const currentObj = current === -1 ? project : currentSection
    console.log(currentObj)

    const [confirmUnpublish, setConfirmUnpublish] = useState(false)
    const [title, setTitle] = useState('')

    const [showParent, setShowParent] = useState(false)
    const [sendEmail, setSendEmail] = useState(true)

    const [published, setPublished] = useState(false)
    const [redirect, doRedirect] = useState(false)

    const [labelWidth, setLabelWidth] = useState(0)
    const labelRef = React.useRef(null)

    let selectedDefault = {}
    console.log('SELECTED DEFAULT:: ', currentSection)

    if (!project.published) {
        for (const s in project.sections) {
            selectedDefault[project.sections[s].id] =
                project.sections[s].bodyText.length > 0
        }
    } else {
        if (currentObj.published_section_ids !== undefined)
            selectedDefault = currentObj.published_section_ids
        else selectedDefault = []

        console.log(currentObj.published_section_ids)
        console.log(selectedDefault)
    }

    const [selectedSections, setSelectedSections] = useState(selectedDefault)

    useEffect(() => {
        console.log('SELECTED SELECTED:: ', selectedSections)
        setSections(selectedSections)
    }, [selectedSections])

    useEffect(() => {
        if (isOverview) {
            setTitle(project.title)
        } else {
            if (currentSection) setTitle(currentSection.title)
        }
    }, [project, currentSection, isOverview])

    useEffect(() => {
        const labelNode = ReactDOM.findDOMNode(labelRef.current)
        setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0)
    })

    const classes = useStyles()

    const generatePublicUrl = (projectId) => {
        return (
            'https://istoria.io/p/' +
            slugUrl(currentObj.title, projectId.slice(0, 7))
        )
    }

    if (redirect)
        return (
            <Redirect
                to={
                    '/p/' + slugUrl(currentObj.title, currentObj.id.slice(0, 7))
                }
            />
        )
    else
        return (
            <Grid item xs={12}>
                <Dialog
                    open={confirmUnpublish}
                    onClose={() => {
                        setConfirmUnpublish(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        {isOverview && (
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to unpublish the project{' '}
                                <b>{project.title}</b>, including all writing?
                                This action can't be undone.
                            </DialogContentText>
                        )}
                        {!isOverview && (
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to unpublish{' '}
                                <b>{currentSection.title}</b> from project{' '}
                                <b>{project.title}</b>? This action can't be
                                undone.
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirmUnpublish(false)
                            }}
                            color="primary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setPublished(false)
                                if (!isOverview) {
                                    dispatch(
                                        unPublishDirect(
                                            project.id,
                                            currentSection.sectionId,
                                            currentObj.published_id
                                        )
                                    )
                                } else {
                                    dispatch(
                                        unPublishDirect(
                                            project.id,
                                            null,
                                            currentObj.published_id
                                        )
                                    )
                                }
                                setConfirmUnpublish(false)
                            }}
                            color="primary"
                        >
                            Unpublish
                        </Button>
                    </DialogActions>
                </Dialog>

                {isOverview && currentObj.published_section_ids && (
                    <div className={classes.root}>
                        <InputLabel
                            ref={labelRef}
                            variant="outlined"
                            className={classes.inputLabel}
                            shrink
                        >
                            Select Writing Segments to Publish
                        </InputLabel>
                        <div className={classes.contentWrapper}>
                            <div className={classes.content}>
                                <NotchedOutline
                                    className={classes.notchedOutline}
                                    notched
                                    labelWidth={labelWidth}
                                />
                                <Container className={classes.publishSections}>
                                    <FormControl
                                        component="fieldset"
                                        className={classes.formControl}
                                    >
                                        <FormGroup>
                                            {project.sections
                                                .sort(compare)
                                                .map((section) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="#512da8"
                                                                name={
                                                                    section.title
                                                                }
                                                            />
                                                        }
                                                        label={section.title}
                                                        onChange={(e) => {
                                                            setSelectedSections(
                                                                {
                                                                    ...selectedSections,
                                                                    [section.id]:
                                                                        e.target
                                                                            .checked,
                                                                }
                                                            )
                                                        }}
                                                        checked={
                                                            selectedSections[
                                                                section.id
                                                            ]
                                                        }
                                                    />
                                                ))}
                                        </FormGroup>
                                    </FormControl>
                                </Container>
                            </div>
                        </div>
                    </div>
                )}
                <Typography variant="h6">Publish Publicly</Typography>
                {currentObj && currentObj.published ? (
                    <>
                        {isOverview && (
                            <Typography className={classes.publishedMessage}>
                                <b>{currentObj.title}</b> is published!
                            </Typography>
                        )}
                        {!isOverview && (
                            <Typography className={classes.publishedMessage}>
                                <b>{currentObj.title}</b> from project{' '}
                                <b>{project.title}</b> is published!
                            </Typography>
                        )}
                        {selectedSections !== selectedDefault &&
                            isOverview &&
                            isSelected(selectedSections) && (
                                <Alert
                                    className={classes.updateMessage}
                                    severity="info"
                                >
                                    Press <b>Update</b> to push changes to your
                                    published project
                                </Alert>
                            )}
                        <Button
                            variant="outlined"
                            color="primary"
                            classes={{ root: classes.publishButton }}
                            startIcon={<FaLink />}
                            onClick={() => {
                                doRedirect(true)
                            }}
                        >
                            View
                        </Button>
                        <br />{' '}
                        {/* <TextField
              className={classes.publishSlug}
              onChange={() => { }}
              value={generateVersionUrl(currentObj.published_id)}
              variant="outlined"
              disabled
              id="publishSlug"
              label="Public URL"
            />
            <br /> */}
                        <TextField
                            className={classes.publishSlug}
                            onChange={() => {}}
                            value={generatePublicUrl(currentObj.id)}
                            variant="outlined"
                            disabled
                            id="shortUrl"
                            label="Public URL"
                        />
                        {/* Project LEvel */}
                        <FeaturedPicker
                            projectId={project.id}
                            sectionId={currentSection?.id}
                        />
                        <Typography
                            className={classes.publishedDate}
                            variant="body2"
                        >
                            {new Date(currentObj.published_on).toString()}
                        </Typography>
                        <Button
                            variant="outlined"
                            classes={{ root: classes.publishButton }}
                            startIcon={<FaSync />}
                            disabled={
                                (isOverview && !isSelected(selectedSections)) ||
                                published === true ||
                                featuredUploading ||
                                projectUpdating
                            }
                            onClick={() => {
                                setPublished(false)

                                if (isOverview) {
                                    dispatch(
                                        unPublishDirect(
                                            project.id,
                                            null,
                                            currentObj.published_id
                                        )
                                    )
                                    dispatch(
                                        publishDirect(
                                            project.title,
                                            null,
                                            project.description,
                                            project.id,
                                            null,
                                            {
                                                profile: profile,
                                                genres: project.genres,
                                                tags: project.tags,
                                            },
                                            selectedSections
                                        )
                                    )
                                } else {
                                    dispatch(
                                        unPublishDirect(
                                            project.id,
                                            currentSection.sectionId,
                                            currentObj.published_id
                                        )
                                    )

                                    dispatch(
                                        publishDirect(
                                            currentSection.title,
                                            project.title,
                                            project.description,
                                            project.id,
                                            currentSection.sectionId,
                                            {
                                                showParent: showParent,
                                                profile: profile,
                                                genres: project.genres,
                                                tags: project.tags,
                                            }
                                        )
                                    )
                                }
                            }}
                        >
                            Update
                        </Button>
                        <Button
                            variant="outlined"
                            classes={{ root: classes.publishButton }}
                            startIcon={<FaTimesCircle />}
                            onClick={() => {
                                setConfirmUnpublish(true)
                            }}
                        >
                            Unpublish
                        </Button>
                    </>
                ) : (
                    <>
                        {isOverview && (
                            <Typography
                                className={classes.publishDescription}
                                variant="body2"
                            >
                                Publishing <b>{title}</b> allows anyone with the
                                URL to read and share, including the selected
                                Writing.
                            </Typography>
                        )}
                        {!isOverview && (
                            <Typography
                                className={classes.publishDescription}
                                variant="body2"
                            >
                                Publishing <b>{title}</b> from project{' '}
                                <b>{project.title}</b> allows anyone with the
                                URL to read and share.
                            </Typography>
                        )}
                        {!isOverview && (
                            <FormGroup
                                className={classes.parentGroup}
                                container
                            >
                                <FormControlLabel
                                    className={classes.parentLabel}
                                    control={
                                        <Switch
                                            checked={showParent}
                                            onChange={() => {
                                                setShowParent(!showParent)
                                            }}
                                            name="showParent"
                                            color="primary"
                                        />
                                    }
                                    label={`Show project title (${project.title}) when published`}
                                />
                            </FormGroup>
                        )}

                        <Container className={classes.publishActions}>
                            <FeaturedPicker
                                projectId={project.id}
                                sectionId={currentSection?.id}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                classes={{ root: classes.publishButton }}
                                startIcon={
                                    isOverview ? (
                                        <FaLayerGroup />
                                    ) : (
                                        <FaRegFileAlt />
                                    )
                                }
                                disabled={
                                    (isOverview &&
                                        !isSelected(selectedSections)) ||
                                    published === true ||
                                    featuredUploading ||
                                    projectUpdating
                                }
                                onClick={() => {
                                    setPublished(true)
                                    if (isOverview) {
                                        console.log('publishing project')
                                        dispatch(
                                            publishDirect(
                                                project.title,
                                                null,
                                                project.description,
                                                project.id,
                                                null,
                                                {
                                                    profile: profile,
                                                    genres: project.genres,
                                                    tags: project.tags,
                                                    sendEmails: true,
                                                },
                                                selectedSections
                                            )
                                        )
                                    } else {
                                        console.log('publishing writing')
                                        dispatch(
                                            publishDirect(
                                                currentSection.title,
                                                project.title,
                                                project.description,
                                                project.id,
                                                currentSection.sectionId,
                                                {
                                                    showParent: showParent,
                                                    profile: profile,
                                                    genres: project.genres,
                                                    tags: project.tags,
                                                    sendEmails: true,
                                                }
                                            )
                                        )
                                    }
                                }}
                            >
                                Publish
                            </Button>
                        </Container>
                    </>
                )}
            </Grid>
        )
}
