import {
    myFirebase,
    db,
    images,
    functions,
    increment,
    decrement,
} from '../firebase/firebase'
import uuid from 'uuid'

import sjcl from 'sjcl'

import { defaultTitle } from '../components/project/WriteEditor'

export const PROJECT_REQUEST = 'PROJECT_REQUEST'
export const PROJECT_SUCCESS = 'PROJECT_SUCCESS'
export const PROJECT_FAILURE = 'PROJECT_FAILURE'
export const PROJECT_COMPLETE = 'PROJECT_COMPLETE'

export const PROJECT_GET_REQUEST = 'PROJECT_GET_REQUEST'
export const PROJECT_GET_SUCCESS = 'PROJECT_GET_SUCCESS'
export const PROJECT_GET_COMPLETE = 'PROJECT_GET_COMPLETE'
export const PROJECT_GET_CLEAR = 'PROJECT_GET_CLEAR'
export const PROJECT_GET_FAILURE = 'PROJECT_GET_FAILURE'

export const PROJECT_LIST_REQUEST = 'PROJECT_LIST_REQUEST'
export const PROJECT_LIST_SUCCESS = 'PROJECT_LIST_SUCCESS'
export const PROJECT_LIST_FAILURE = 'PROJECT_LIST_FAILURE'

export const COMMENTS_LIST = 'COMMENTS_LIST'
export const COMMENTS_LIST_SUCCESS = 'COMMENTS_LIST_SUCCESS'
export const COMMENTS_LIST_FAILURE = 'COMMENTS_LIST_FAILURE'

export const PROJECT_UPDATE = 'PROJECT_UPDATE'
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS'
export const PROJECT_UPDATE_FAILURE = 'PROJECT_UPDATE_FAILURE'

export const PUBLISHED_LIST = 'PUBLISHED_LIST'
export const PUBLISHED_LIST_SUCCESS = 'PUBLISHED_LIST_SUCCESS'
export const PUBLISHED_LIST_FAILURE = 'PUBLISHED_LIST_FAILURE'

export const PROJECT_DELETE = 'PROJECT_DELETE'
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS'
export const PROJECT_DELETE_FAILURE = 'PROJECT_DELETE_FAILURE'
export const PROJECT_DELETE_CLEAR = 'PROJECT_DELETE_FAILURE'

export const EDITOR_SAVE = 'EDITOR_SAVE'
export const EDITOR_SAVE_SUCCESS = 'EDITOR_SAVE_SUCCESS'
export const EDITOR_SAVE_FAILURE = 'EDITOR_SAVE_FAILURE'

export const PUBLISHED_GET_BEGIN = 'PUBLISHED_GET_BEGIN'
export const PUBLISHED_GET_SUCCESS = 'PUBLISHED_GET_SUCCESS'
export const PUBLISHED_GET_FAILURE = 'PUBLISHED_GET_FAILURE'
export const PUBLISHED_GET_CLEAR = 'PUBLISHED_GET_CLEAR'

export const SECTIONS_GET_SUCCESS = 'SECTIONS_GET_SUCCESS'
export const NOTES_GET_SUCCESS = 'NOTES_GET_SUCCESS'

export const UPLOAD_FEATURED = 'UPLOAD_FEATURED'
export const UPLOAD_FEATURED_SUCCESS = 'UPLOAD_FEATURED_SUCCESS'
export const UPLOAD_FEATURED_FAILURE = 'UPLOAD_FEATURED_FAILURE'

const deleteProject = () => {
    return {
        type: PROJECT_DELETE,
    }
}
const deleteProjectSuccess = () => {
    return {
        type: PROJECT_DELETE_SUCCESS,
    }
}
const deleteProjectFailure = () => {
    return {
        type: PROJECT_DELETE_FAILURE,
    }
}
const listCommentsSuccess = (comments) => {
    return {
        type: COMMENTS_LIST_SUCCESS,
        comments: comments,
    }
}
//test commit
export const listProjectComments =
    (projectId, sectionId, communityId) => (dispatch) => {
        dispatch(listPublished())
        let commentsRef

        if (sectionId) {
            commentsRef = db
                .collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
                .collection('comments')
        } else {
            commentsRef = db
                .collection('projects')
                .doc(projectId)
                .collection('comments')
        }

        if (communityId) {
            commentsRef = commentsRef.where('communityId', '==', communityId)
        }

        commentsRef.onSnapshot((snapshot) => {
            let comments = []
            snapshot.forEach((comment) => {
                if (!comment.data().communityId || communityId) {
                    // console.log(communityId)
                    // console.log(comment.data())
                    comments.push({ id: comment.id, ...comment.data() })
                }
            })
            // console.log(comments)
            dispatch(listCommentsSuccess(comments))
        })
    }

export const deleteComment =
    (projectId, sectionId, commentId) => (dispatch) => {
        // console.log('deleting comment ' + commentId)
        let commentRef

        if (sectionId) {
            commentRef = db
                .collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
                .collection('comments')
                .doc(commentId)
        } else {
            commentRef = db
                .collection('projects')
                .doc(projectId)
                .collection('comments')
                .doc(commentId)
        }

        commentRef
            .delete()
            .then(() => {
                // console.log('comment deleted')
            })
            .catch((error) => {
                // console.log(error)
            })
    }

export const saveComment =
    (projectId, sectionId, communityId, options) => (dispatch) => {
        // console.log('saving comment')
        let commentsRef
        if (sectionId) {
            commentsRef = db
                .collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
                .collection('comments')
        } else {
            commentsRef = db
                .collection('projects')
                .doc(projectId)
                .collection('comments')
        }
        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                commentsRef
                    .add({
                        profile: options.profile,
                        uid: options.profile.uid,
                        token: idToken,
                        text: options.text,
                        communityId: communityId,
                        commented_on: new Date().toISOString(),
                    })
                    .then(() => {
                        // console.log('comment saved')
                        var sendCommentEmail =
                            functions.httpsCallable('sendCommentEmail')
                        //sendCommentEmail({ projectId, sectionId, profileId: options.profile.id })
                    })
                    .catch((error) => {
                        // console.log(error)
                    })
            })
    }

const listPublished = () => {
    return {
        type: PUBLISHED_LIST,
    }
}
const listPublishedSuccess = (publishedList) => {
    return {
        type: PUBLISHED_LIST_SUCCESS,
        publishedList: publishedList,
    }
}
const listPublishedFailure = () => {
    return {
        type: PUBLISHED_LIST_FAILURE,
    }
}

export const deleteProjectClear = () => {
    // console.log('clear')
    return {
        type: PROJECT_DELETE_CLEAR,
    }
}

export const deleteUserProject = (projectId) => (dispatch) => {
    dispatch(deleteProject())
    let projectsRef = db.collection('projects')
    let publishedRef = db.collection('published')

    publishedRef.where('projectId', '==', projectId).onSnapshot(
        (snapshot) => {
            snapshot.forEach((doc) => {
                publishedRef
                    .doc(doc.id)
                    .delete()
                    .then(() => {
                        // console.log('delete published ' + doc.id)
                    })
            })

            projectsRef
                .doc(projectId)
                .delete()
                .then(() => {
                    // console.log('delete project success')
                    dispatch(deleteProjectSuccess())
                })
                .catch((error) => {
                    dispatch(deleteProjectFailure())
                })
        },
        (error) => {
            // console.log('error deleting published for project ' + projectId)
            dispatch(deleteProjectFailure())
        }
    )
}
const updateProject = () => {
    // console.log('project update')
    return {
        type: PROJECT_UPDATE,
    }
}
const updateProjectSuccess = () => {
    return {
        type: PROJECT_UPDATE_SUCCESS,
    }
}
const updateProjectFailure = () => {
    return {
        type: PROJECT_UPDATE_FAILURE,
    }
}

export const getFromAssociation = (shortUrl, communityId) => (dispatch) => {
    dispatch(getPublishedBegin())
    let associationRef
    if (communityId) {
        // console.log('get association from community')
        associationRef = db
            .collection('url_association')
            .where('communityId', '==', communityId)
    } else {
        // console.log('get association from direct')
        associationRef = db.collection('url_association')
    }
    let publishedRef = db.collection('published')

    associationRef
        .where('shortUrl', '==', shortUrl)
        .get()
        .then((snapshot) => {
            if (snapshot.empty) {
                // console.log('empty snapshot')
                dispatch(getPublishedFailure())
            } else {
                let resultFound = false
                snapshot.forEach((doc) => {
                    // console.log(doc.data())
                    if (
                        (communityId && doc.data().communityId) ||
                        (!communityId && !doc.data().communityId)
                    ) {
                        resultFound = true
                        db.collection('url_association')
                            .doc(doc.id)
                            .get()
                            .then(() => {
                                publishedRef
                                    .where(
                                        'projectId',
                                        '==',
                                        doc.data().projectId
                                    )
                                    .where(
                                        'sectionId',
                                        '==',
                                        doc.data().sectionId
                                    )
                                    .get()
                                    .then((snapshot) => {
                                        snapshot.forEach((pub) => {
                                            dispatch(
                                                getPublishedSuccess({
                                                    ...pub.data(),
                                                    id: pub.id,
                                                    publishedId: pub.id,
                                                })
                                            )
                                        })
                                    })
                            })
                    }
                    if (!resultFound) {
                        dispatch(getPublishedFailure())
                    }
                })
            }
        })
}

export const getPublished = (publishedId) => (dispatch) => {
    dispatch(getPublishedBegin())

    // console.log('got published ' + publishedId)

    let publishedRef = db.collection('published')
    publishedRef.doc(publishedId).onSnapshot(function (doc) {
        dispatch(getPublishedSuccess({ ...doc.data(), id: doc.id }))
    })
}

export const clearPublished = () => {
    return {
        type: PUBLISHED_GET_CLEAR,
    }
}
const getPublishedBegin = () => {
    return {
        type: PUBLISHED_GET_BEGIN,
    }
}
const getPublishedSuccess = (published) => {
    return {
        type: PUBLISHED_GET_SUCCESS,
        published: published,
    }
}

const getPublishedFailure = () => {
    return {
        type: PUBLISHED_GET_FAILURE,
    }
}
export const unPublishFromCommunity =
    (projectId, sectionId, publishedId, communityId) => (dispatch) => {
        // console.log(
        //     'unpublishing ' +
        //         projectId +
        //         '/' +
        //         sectionId +
        //         ' from community ' +
        //         communityId +
        //         ' from published ' +
        //         publishedId
        // )
        let docRef
        if (sectionId) {
            docRef = db
                .collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
            deleteAssociation(projectId, sectionId, communityId)
        } else {
            docRef = db.collection('projects').doc(projectId)
            deleteAssociation(projectId, null)
        }
        docRef
            .update({
                published_community_on: null,
                published_community_id: null,
                community_id: null,
                published_to_community: null,
                community_name: null,
                community_published_id: null,
            })
            .then(() => {
                db.collection('published')
                    .doc(publishedId)
                    .delete()
                    .then(() => {
                        // console.log('successfully unpublished from community')
                        if (sectionId) {
                            dispatch(getProject(projectId))
                        }
                    })
                    .catch((error) => {
                        // console.log('error deleting section')
                        // console.log(error)
                    })
            })
            .catch((error) => {
                // console.log('error unpublishing')
                // console.log(error)
            })
    }
export const unPublishDirect =
    (projectId, sectionId, publishedId) => (dispatch) => {
        // console.log('unpublishing direct ' + projectId + '/' + sectionId)
        let docRef
        if (sectionId) {
            docRef = db
                .collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
            deleteAssociation(projectId, sectionId)
        } else {
            docRef = db.collection('projects').doc(projectId)
            deleteAssociation(projectId, null)
        }
        docRef
            .update({ published_on: null, published_id: null, published: null })
            .then(() => {
                db.collection('published')
                    .doc(publishedId)
                    .delete()
                    .then(() => {
                        // console.log('successfully unpublished direct')
                        if (sectionId) {
                            dispatch(getProject(projectId))
                        }
                    })
                    .catch((error) => {
                        // console.log('error deleting section')
                        // console.log(error)
                    })
            })
            .catch((error) => {
                // console.log('error unpublishing')
                // console.log(error)
            })
    }

const deleteAssociation = (projectId, sectionId, communityId) => {
    let associationRef = db.collection('url_association')
    associationRef
        .where('projectId', '==', projectId)
        .where('sectionId', '==', sectionId)
        .get()
        .then((snapshot) => {
            snapshot.forEach((doc) => {
                if (
                    (communityId && doc.data().communityId) ||
                    (!communityId && !doc.data().communityId)
                ) {
                    associationRef
                        .doc(doc.id)
                        .delete()
                        .then(() => {
                            // console.log('delete association ' + doc.id)
                        })
                }
            })
        })
}

export const publishDirect =
    (
        title,
        fromTitle,
        description,
        projectId,
        sectionId,
        options,
        selectedSections
    ) =>
    (dispatch) => {
        // console.log(options)

        let profile = options.profile
        // console.log(profile)
        // console.log('publishing direct ' + projectId + '/' + sectionId)
        // console.log(selectedSections)
        let docRef
        if (sectionId) {
            docRef = db
                .collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
        } else {
            docRef = db.collection('projects').doc(projectId)
        }

        console.log('TRYING TO SECTION THINGS')
        docRef.get().then(function (doc) {
            if (!sectionId) {
                let sections = []
                console.log('NO SECTION ID')
                docRef
                    .collection('sections')
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach((section) => {
                            const sectionData = section.data()
                            let index = sections.findIndex(
                                (s) => s.sectionId === section.id
                            )
                            console.log('SECTIONS::', section)
                            if (index === -1) {
                                // console.log(section.id)
                                if (selectedSections[section.id]) {
                                    // console.log(sectionData.title + ' selected')
                                    sections.push({
                                        title: sectionData.title,
                                        body: sectionData.encrypted
                                            ? deobfuscate(sectionData.body)
                                            : sectionData.body,
                                        bodyText: sectionData.encrypted
                                            ? deobfuscate(sectionData.bodyText)
                                            : sectionData.bodyText,
                                        position: sectionData.position,
                                        sectionId: sectionData.sectionId,
                                    })
                                }
                            }
                        })
                        // console.log(sections)
                        myFirebase
                            .auth()
                            .currentUser.getIdToken(true)
                            .then(function (idToken) {
                                db.collection('published')
                                    .add({
                                        title: title,
                                        fromTitle: fromTitle,
                                        description:
                                            description !== undefined
                                                ? description
                                                : null,
                                        excerpt: sections[0].bodyText,
                                        projectId: projectId,
                                        likeCount: 0,
                                        genres:
                                            options.genres !== undefined
                                                ? options.genres
                                                : null,
                                        tags:
                                            options.tags !== undefined
                                                ? options.tags
                                                : null,
                                        shortUrl: doc.id.slice(0, 7),
                                        sectionId: sectionId ? sectionId : null,
                                        featuredId: doc.data().featuredId
                                            ? doc.data().featuredId
                                            : null,
                                        publishedSections: sections,
                                        published_on: new Date().toISOString(),
                                        published_section_ids: selectedSections,
                                        published: true,
                                        profile: profile,
                                        uid: profile.uid,
                                        profileId: profile.id,
                                        ver: 4,
                                    })
                                    .then((pubRef) => {
                                        docRef
                                            .update({
                                                published_on:
                                                    new Date().toISOString(),
                                                published_section_ids:
                                                    selectedSections,
                                                published_id: pubRef.id,
                                                published: true,
                                            })
                                            .then(() => {
                                                myFirebase
                                                    .auth()
                                                    .currentUser.getIdToken(
                                                        true
                                                    )
                                                    .then(function (idToken) {
                                                        db.collection(
                                                            'url_association'
                                                        )
                                                            .add({
                                                                projectId:
                                                                    projectId,
                                                                sectionId: null,
                                                                publishedId:
                                                                    pubRef.id,
                                                                shortUrl:
                                                                    doc.id.slice(
                                                                        0,
                                                                        7
                                                                    ),
                                                                active: true,
                                                            })
                                                            .then((ref) => {
                                                                // console.log(
                                                                //     'successfully published project'
                                                                // )
                                                                if (
                                                                    options.sendEmails
                                                                ) {
                                                                    // console.log(
                                                                    //     'sending published email updates'
                                                                    // )
                                                                    sendPublishEmails(
                                                                        pubRef.id
                                                                    )
                                                                }
                                                                dispatch(
                                                                    getProject(
                                                                        projectId
                                                                    )
                                                                )
                                                            })
                                                    })
                                                    .catch((error) => {
                                                        // console.log(
                                                        //     'error publishing'
                                                        // )
                                                        // console.log(error)
                                                    })
                                            })
                                    })
                                    .catch((error) => {
                                        // console.log('error publishing')
                                        // console.log(error)
                                    })
                            })
                            .catch((error) => {
                                // console.log('error publishing')
                                // console.log(error)
                            })
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            } else {
                console.log('SECTION ID EXIST')
                myFirebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(function (idToken) {
                        // console.log({
                        //     title: doc.data().title,
                        //     default:
                        //         doc.data().default !== undefined
                        //             ? doc.data().default
                        //             : null,
                        //     body: doc.data().encrypted
                        //         ? deobfuscate(doc.data().body)
                        //         : doc.data().body,
                        //     bodyText: doc.data().encrypted
                        //         ? deobfuscate(doc.data().bodyText)
                        //         : doc.data().bodyText,
                        //     showParent:
                        //         options !== undefined &&
                        //         options.showParent !== undefined
                        //             ? options.showParent
                        //             : false,
                        // })
                        db.collection('published')
                            .add({
                                title: title,
                                fromTitle: fromTitle,
                                excerpt: doc.data().encrypted
                                    ? deobfuscate(doc.data().bodyText)
                                    : doc.data().bodyText,
                                description:
                                    description !== undefined
                                        ? description
                                        : null,
                                projectId: projectId,
                                likeCount: 0,
                                genres:
                                    options.genres !== undefined
                                        ? options.genres
                                        : null,
                                tags:
                                    options.tags !== undefined
                                        ? options.tags
                                        : null,
                                shortUrl: doc.id.slice(0, 7),
                                sectionId: sectionId ? sectionId : null,
                                featuredId: doc.data().featuredId
                                    ? doc.data().featuredId
                                    : null,
                                showParent:
                                    options !== undefined &&
                                    options.showParent !== undefined
                                        ? options.showParent
                                        : false,
                                publishedSections: [
                                    {
                                        title: doc.data().title,
                                        default:
                                            doc.data().default !== undefined
                                                ? doc.data().default
                                                : null,
                                        body: doc.data().encrypted
                                            ? deobfuscate(doc.data().body)
                                            : doc.data().body,
                                        bodyText: doc.data().encrypted
                                            ? deobfuscate(doc.data().bodyText)
                                            : doc.data().bodyText,
                                    },
                                ],
                                published_on: new Date().toISOString(),
                                published: true,
                                profile: profile,
                                uid: profile.uid,
                                profileId: profile.id,
                                ver: 4,
                            })
                            .then((ref) => {
                                docRef
                                    .update({
                                        published_on: new Date().toISOString(),
                                        published_id: ref.id,
                                        published: true,
                                        // featuredId: selectedSections.featuredId,
                                    })
                                    .then(() => {
                                        myFirebase
                                            .auth()
                                            .currentUser.getIdToken(true)
                                            .then(function (idToken) {
                                                db.collection('url_association')
                                                    .add({
                                                        projectId: projectId,
                                                        sectionId: sectionId,
                                                        publishedId: ref.id,
                                                        shortUrl: doc.id.slice(
                                                            0,
                                                            7
                                                        ),
                                                        active: true,
                                                    })
                                                    .then((ref) => {
                                                        // console.log(
                                                        //     'successfully published project'
                                                        // )
                                                        dispatch(
                                                            getProject(
                                                                projectId
                                                            )
                                                        )
                                                    })
                                            })
                                            .catch((error) => {
                                                // console.log('error publishing')
                                                // console.log(error)
                                            })
                                    })
                                    .catch((error) => {
                                        // console.log('error publishing')
                                        // console.log(error)
                                    })
                            })
                            .catch((error) => {
                                // console.log('error publishing')
                                // console.log(error)
                            })
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        })
    }
export const publishToCommunity =
    (
        title,
        fromTitle,
        description,
        projectId,
        sectionId,
        options,
        selectedSections,
        communityId,
        communityName
    ) =>
    (dispatch) => {
        // console.log(options)

        let profile = options.profile
        // console.log(profile)
        // console.log(
        //     'publishing ' +
        //         projectId +
        //         '/' +
        //         sectionId +
        //         ' to communuity ' +
        //         communityId
        // )
        // console.log(selectedSections)
        let docRef
        if (sectionId) {
            docRef = db
                .collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
        } else {
            docRef = db.collection('projects').doc(projectId)
        }

        docRef.get().then(function (doc) {
            if (!sectionId) {
                let sections = []
                docRef
                    .collection('sections')
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach((section) => {
                            const sectionData = section.data()
                            let index = sections.findIndex(
                                (s) => s.sectionId === section.id
                            )
                            if (index === -1) {
                                // console.log(section.id)
                                if (selectedSections[section.id]) {
                                    // console.log(sectionData.title + ' selected')
                                    sections.push({
                                        title: sectionData.title,
                                        body: sectionData.encrypted
                                            ? deobfuscate(sectionData.body)
                                            : sectionData.body,
                                        bodyText: sectionData.encrypted
                                            ? deobfuscate(sectionData.bodyText)
                                            : sectionData.bodyText,
                                        position: sectionData.position,
                                    })
                                }
                            }
                        })
                        // console.log(sections)
                        myFirebase
                            .auth()
                            .currentUser.getIdToken(true)
                            .then(function (idToken) {
                                db.collection('published')
                                    .add({
                                        title: title,
                                        fromTitle: fromTitle,
                                        description:
                                            description !== undefined
                                                ? description
                                                : null,
                                        excerpt: sections[0].bodyText,
                                        projectId: projectId,
                                        likeCount: 0,
                                        genres:
                                            options.genres !== undefined
                                                ? options.genres
                                                : null,
                                        tags:
                                            options.tags !== undefined
                                                ? options.tags
                                                : null,
                                        shortUrl: doc.id.slice(0, 7),
                                        sectionId: sectionId ? sectionId : null,
                                        featuredId: doc.data().featuredId
                                            ? doc.data().featuredId
                                            : null,
                                        publishedSections: sections,
                                        published_on: new Date().toISOString(),
                                        published: false,
                                        published_section_ids: selectedSections,
                                        published_to_community: true,
                                        community_id: communityId,
                                        community_name: communityName,
                                        published_community_on:
                                            new Date().toISOString(),
                                        profile: profile,
                                        uid: profile.uid,
                                        profileId: profile.id,
                                        ver: 4,
                                    })
                                    .then((pubRef) => {
                                        docRef
                                            .update({
                                                published_community_on:
                                                    new Date().toISOString(),
                                                published_section_ids:
                                                    selectedSections,
                                                published_to_community: true,
                                                community_id: communityId,
                                                community_name: communityName,
                                                community_published_id:
                                                    pubRef.id,
                                            })
                                            .then(() => {
                                                myFirebase
                                                    .auth()
                                                    .currentUser.getIdToken(
                                                        true
                                                    )
                                                    .then(function (idToken) {
                                                        db.collection(
                                                            'url_association'
                                                        )
                                                            .add({
                                                                projectId:
                                                                    projectId,
                                                                sectionId: null,
                                                                communityId:
                                                                    communityId,
                                                                shortUrl:
                                                                    doc.id.slice(
                                                                        0,
                                                                        7
                                                                    ),
                                                                active: true,
                                                            })
                                                            .then((ref) => {
                                                                // console.log(
                                                                //     'successfully published project'
                                                                // )
                                                                if (
                                                                    options.sendEmails
                                                                ) {
                                                                    // console.log(
                                                                    //     'sending published email updates'
                                                                    // )
                                                                    sendPublishEmails(
                                                                        pubRef.id
                                                                    )
                                                                }
                                                                dispatch(
                                                                    getProject(
                                                                        projectId
                                                                    )
                                                                )
                                                            })
                                                    })
                                                    .catch((error) => {
                                                        // console.log(
                                                        //     'error publishing'
                                                        // )
                                                        // console.log(error)
                                                    })
                                            })
                                    })
                                    .catch((error) => {
                                        // console.log('error publishing')
                                        // console.log(error)
                                    })
                            })
                            .catch((error) => {
                                // console.log('error publishing')
                                // console.log(error)
                            })
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            } else {
                myFirebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(function (idToken) {
                        // console.log({
                        //     title: doc.data().title,
                        //     default:
                        //         doc.data().default !== undefined
                        //             ? doc.data().default
                        //             : null,
                        //     body: doc.data().encrypted
                        //         ? deobfuscate(doc.data().body)
                        //         : doc.data().body,
                        //     bodyText: doc.data().encrypted
                        //         ? deobfuscate(doc.data().bodyText)
                        //         : doc.data().bodyText,
                        //     showParent:
                        //         options !== undefined &&
                        //         options.showParent !== undefined
                        //             ? options.showParent
                        //             : false,
                        // })
                        db.collection('published')
                            .add({
                                title: title,
                                fromTitle: fromTitle,
                                excerpt: doc.data().encrypted
                                    ? deobfuscate(doc.data().bodyText)
                                    : doc.data().bodyText,
                                description:
                                    description !== undefined
                                        ? description
                                        : null,
                                projectId: projectId,
                                likeCount: 0,
                                genres:
                                    options.genres !== undefined
                                        ? options.genres
                                        : null,
                                tags:
                                    options.tags !== undefined
                                        ? options.tags
                                        : null,
                                shortUrl: doc.id.slice(0, 7),
                                sectionId: sectionId ? sectionId : null,
                                featuredId: doc.data().featuredId
                                    ? doc.data().featuredId
                                    : null,
                                showParent:
                                    options !== undefined &&
                                    options.showParent !== undefined
                                        ? options.showParent
                                        : false,
                                publishedSections: [
                                    {
                                        title: doc.data().title,
                                        default:
                                            doc.data().default !== undefined
                                                ? doc.data().default
                                                : null,
                                        body: doc.data().encrypted
                                            ? deobfuscate(doc.data().body)
                                            : doc.data().body,
                                        bodyText: doc.data().encrypted
                                            ? deobfuscate(doc.data().bodyText)
                                            : doc.data().bodyText,
                                    },
                                ],
                                published_on: new Date().toISOString(),
                                published: false,

                                published_section_ids: selectedSections,
                                published_to_community: true,
                                community_id: communityId,
                                community_name: communityName,
                                published_community_on:
                                    new Date().toISOString(),
                                profile: profile,
                                uid: profile.uid,
                                profileId: profile.id,
                                ver: 4,
                            })
                            .then((ref) => {
                                docRef
                                    .update({
                                        published_community_on:
                                            new Date().toISOString(),
                                        published_section_ids: selectedSections,
                                        published_to_community: true,
                                        community_id: communityId,
                                        community_name: communityName,
                                        community_published_id: ref.id,
                                    })
                                    .then(() => {
                                        myFirebase
                                            .auth()
                                            .currentUser.getIdToken(true)
                                            .then(function (idToken) {
                                                db.collection('url_association')
                                                    .add({
                                                        projectId: projectId,
                                                        sectionId: sectionId,
                                                        communityId:
                                                            communityId,
                                                        shortUrl: doc.id.slice(
                                                            0,
                                                            7
                                                        ),
                                                        active: true,
                                                    })
                                                    .then((ref) => {
                                                        // console.log(
                                                        //     'successfully published project'
                                                        // )
                                                        dispatch(
                                                            getProject(
                                                                projectId
                                                            )
                                                        )
                                                    })
                                            })
                                            .catch((error) => {
                                                // console.log('error publishing')
                                                // console.log(error)
                                            })
                                    })
                                    .catch((error) => {
                                        // console.log('error publishing')
                                        // console.log(error)
                                    })
                            })
                            .catch((error) => {
                                // console.log('error publishing')
                                // console.log(error)
                            })
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        })
    }
export const likePublished = (publishedId) => (dispatch) => {
    let publishedRef = db.collection('published')
    let profilesRef = db.collection('profiles')

    publishedRef
        .doc(publishedId)
        .get()
        .then(function (doc) {
            let oldData = doc.data()
            let liked_uids = doc.data().liked_uids || []
            let liked_count =
                isNaN(doc.data().liked_count) || !doc.data().liked_uids
                    ? 0
                    : doc.data().liked_count
            let uid = myFirebase.auth().currentUser.uid

            if (!liked_uids.includes(uid)) {
                liked_uids.push(uid)
                console.log(liked_uids)
                // console.log(liked_count)
                publishedRef
                    .doc(publishedId)
                    .update({
                        liked_count: increment,
                        liked_uids: [...new Set(liked_uids)],
                    })
                    .then(() => {
                        // console.log(uid + ' liked ' + publishedId)

                        profilesRef
                            .where('uid', '==', uid)
                            .get()
                            .then((snapshot) => {
                                snapshot.forEach((doc) => {
                                    const data = doc.data()
                                    console.log('========data', data, uid)
                                    console.log(publishedId)
                                    profilesRef.doc(doc.id).update({
                                        likedPublishes: [
                                            ...(data.likedPublishes || []),
                                            oldData.uid,
                                        ],
                                    })
                                })
                            })
                            .catch((error) => {
                                console.log('error', error.message)
                            })
                    })
                    .catch((error) => {
                        console.log('error')
                    })
            }
        })
}

export const unlikePublished = (publishedId) => (dispatch) => {
    let publishedRef = db.collection('published')
    let profilesRef = db.collection('profiles')
    publishedRef
        .doc(publishedId)
        .get()
        .then(function (doc) {
            let oldData = doc.data()
            let liked_uids = doc.data().liked_uids || []
            let liked_count = doc.data().liked_count

            let uid = myFirebase.auth().currentUser.uid

            if (liked_uids.includes(uid)) {
                liked_uids.splice(liked_uids.indexOf(uid), 1)
                // console.log(liked_uids)
                //console.log(liked_count)
                publishedRef
                    .doc(publishedId)
                    .update({
                        liked_count: liked_count > 0 ? decrement : 0,
                        liked_uids: [...new Set(liked_uids)],
                    })
                    .then(() => {
                        profilesRef
                            .where('uid', '==', uid)
                            .get()
                            .then((snapshot) => {
                                snapshot.forEach((doc) => {
                                    const data = doc.data()
                                    console.log('========data', data, uid)
                                    console.log(publishedId)
                                    profilesRef.doc(doc.id).update({
                                        likedPublishes:
                                            data.likedPublishes.filter(
                                                (publish) =>
                                                    publish !== oldData.uid
                                            ),
                                    })
                                })
                            })
                            .catch((error) => {
                                console.log('error', error.message)
                            })
                    })
                    .catch((error) => {
                        console.log('error')
                    })
            }
        })
}

export const likeSection = (sectionID) => (dispatch) => {
    let publishedRef = db.collection('published')
    publishedRef
        .doc(sectionID)
        .get()
        .then(function (doc) {
            let liked_section_ids = doc.data().liked_section_ids || []
            let liked_section_count =
                isNaN(doc.data().liked_section_count) ||
                !doc.data().liked_section_ids
                    ? 0
                    : doc.data().liked_section_count
            let uid = myFirebase.auth().currentUser.uid

            if (!liked_section_ids.includes(uid)) {
                liked_section_ids.push(uid)
                // console.log(liked_section_ids)
                // console.log(liked_section_count)
                publishedRef
                    .doc(sectionID)
                    .update({
                        liked_section_count: increment,
                        liked_section_ids: liked_section_ids,
                    })
                    .then(() => {
                        // console.log(uid + ' liked ' + sectionID)
                    })
                    .catch((error) => {
                        // console.log('error')
                    })
            }
        })
}

export const unlikeSection = (sectionID) => (dispatch) => {
    let publishedRef = db.collection('published')
    publishedRef
        .doc(sectionID)
        .get()
        .then(function (doc) {
            let liked_section_ids = doc.data().liked_section_ids || []
            let liked_section_count = doc.data().liked_section_count

            let uid = myFirebase.auth().currentUser.uid

            if (liked_section_ids.includes(uid)) {
                liked_section_ids.splice(liked_section_ids.indexOf(uid), 1)
                // console.log(liked_section_ids)
                // console.log(liked_section_count)
                publishedRef
                    .doc(sectionID)
                    .update({
                        liked_section_count:
                            liked_section_count > 0 ? decrement : 0,
                        liked_section_ids: liked_section_ids,
                    })
                    .then(() => {
                        // console.log(uid + ' unliked ' + sectionID)
                    })
                    .catch((error) => {
                        // console.log('error')
                    })
            }
        })
}

export const deleteSection = (projectId, sectionId) => (dispatch) => {
    // console.log('deleting section')
    // console.log(sectionId)
    let publishedRef = db.collection('published')

    publishedRef.where('sectionId', '==', sectionId).onSnapshot(
        (snapshot) => {
            snapshot.forEach((doc) => {
                publishedRef
                    .doc(doc.id)
                    .delete()
                    .then(() => {
                        // console.log('delete published ' + doc.id)
                    })
            })

            myFirebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    db.collection('projects')
                        .doc(projectId)
                        .collection('sections')
                        .doc(sectionId)
                        .delete()
                        .then(() => {
                            // console.log('section deleted')
                        })
                        .catch((error) => {
                            // console.log('error deleting section')
                            // console.log(error)
                        })
                })
                .catch(function (error) {
                    // console.log('error deleting section')
                    // console.log(error)
                })
        },
        (error) => {
            // console.log('error deleting section')
            // console.log(error)
        }
    )
}
export const renameSection =
    (projectId, sectionId, title, unDefault) => (dispatch) => {
        // console.log('renaming section')
        // console.log(sectionId)
        // console.log(unDefault)

        let sectionObj = {
            title: title,
        }

        if (unDefault) sectionObj.default = false

        // console.log(sectionObj)

        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                db.collection('projects')
                    .doc(projectId)
                    .collection('sections')
                    .doc(sectionId)
                    .update(sectionObj)
                    .then(() => {
                        // console.log('section renamed')
                    })
                    .catch((error) => {
                        // console.log('error renaming section')
                        // console.log(error)
                    })
            })
            .catch(function (error) {
                // console.log('error renaming section')
                // console.log(error)
            })
    }

export const editSection = (projectId, sectionId, payload) => (dispatch) => {
    // console.log('updating section details')
    // console.log(sectionId)

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
                .update({
                    title: payload.sectionTitle,
                    description: payload.sectionDescription,
                })
                .then(() => {
                    // console.log('section renamed')
                })
                .catch((error) => {
                    // console.log('error updating section details')
                    // console.log(error)
                })
        })
        .catch(function (error) {
            // console.log('error updating section details')
            // console.log(error)
        })
}

export const copySection = (projectId, sectionId) => (dispatch) => {
    // console.log('copying section')
    // console.log(sectionId)

    let sectionPosition
    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .get()
                .then((doc) => {
                    sectionPosition = doc.data().sectionPosition
                })
        })

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(sectionId)
                .get()
                .then((doc) => {
                    // console.log(sectionPosition)
                    let copyData = doc.data()
                    copyData.title = copyData.title + ' (copy)'
                    // console.log(copyData)
                    dispatch(
                        addSection(
                            copyData.title,
                            sectionPosition + 1,
                            deobfuscate(copyData.body),
                            deobfuscate(copyData.bodyText),
                            projectId
                        )
                    )
                })
                .catch((error) => {
                    // console.log('error copying section')
                    // console.log(error)
                })
        })
        .catch(function (error) {
            // console.log('error copying section')
            // console.log(error)
        })
}

export const moveUpSection = (projectId, section) => (dispatch) => {
    // console.log('moving section up')
    // console.log(section.sectionId)

    let startPos = section.position

    let prevSecId = null
    let prevSecPos = null
    let done = false

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('sections')
                .orderBy('position')
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        let sectionData = doc.data()
                        let curSecId = doc.id

                        if (sectionData.position === startPos && !done) {
                            // console.log('moving up')
                            let grab = prevSecId
                            if (!prevSecId) {
                                //at top do nothing
                                // console.log('at top, no move')
                                done = true
                            } else {
                                //move prev down one step
                                myFirebase
                                    .auth()
                                    .currentUser.getIdToken(true)
                                    .then(function (idToken) {
                                        // console.log('updating ' + grab)
                                        db.collection('projects')
                                            .doc(projectId)
                                            .collection('sections')
                                            .doc(grab)
                                            .update({
                                                position: startPos,
                                                role: 'prev',
                                            })
                                            .then(() => {
                                                // console.log(
                                                //     'prev position updated'
                                                // )

                                                myFirebase
                                                    .auth()
                                                    .currentUser.getIdToken(
                                                        true
                                                    )
                                                    .then(function (idToken) {
                                                        db.collection(
                                                            'projects'
                                                        )
                                                            .doc(projectId)
                                                            .collection(
                                                                'sections'
                                                            )
                                                            .doc(curSecId)
                                                            .update({
                                                                position:
                                                                    startPos -
                                                                    1,
                                                                role: 'target',
                                                            })
                                                            .then(() => {
                                                                // console.log(
                                                                //     'target position updated'
                                                                // )
                                                                done = true
                                                            })
                                                    })
                                            })
                                    })
                            }
                        }

                        prevSecId = doc.id
                        prevSecPos = sectionData.position
                    })
                })
        })
        .catch(function (error) {
            // console.log('error moving section')
            // console.log(error)
        })
}

export const moveDownSection = (projectId, section) => (dispatch) => {
    // console.log('moving section down')
    // console.log(section.sectionId)

    let startPos = section.position

    let prevSecId = null
    let prevSecPos = null
    let done = false

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('sections')
                .orderBy('position', 'desc')
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        let sectionData = doc.data()
                        let curSecId = doc.id

                        if (sectionData.position === startPos && !done) {
                            // console.log('moving down')
                            let grab = prevSecId
                            if (!prevSecId) {
                                // console.log('at bottom, no move')
                                done = true
                            } else {
                                //move prev up one step
                                myFirebase
                                    .auth()
                                    .currentUser.getIdToken(true)
                                    .then(function (idToken) {
                                        // console.log('updating ' + grab)
                                        db.collection('projects')
                                            .doc(projectId)
                                            .collection('sections')
                                            .doc(grab)
                                            .update({
                                                position: startPos,
                                                role: 'prev',
                                            })
                                            .then(() => {
                                                // console.log(
                                                //     'prev position updated'
                                                // )

                                                myFirebase
                                                    .auth()
                                                    .currentUser.getIdToken(
                                                        true
                                                    )
                                                    .then(function (idToken) {
                                                        db.collection(
                                                            'projects'
                                                        )
                                                            .doc(projectId)
                                                            .collection(
                                                                'sections'
                                                            )
                                                            .doc(curSecId)
                                                            .update({
                                                                position:
                                                                    startPos +
                                                                    1,
                                                                role: 'target',
                                                            })
                                                            .then(() => {
                                                                // console.log(
                                                                //     'target position updated'
                                                                // )
                                                                done = true
                                                            })
                                                    })
                                            })
                                    })
                            }
                        }

                        prevSecId = doc.id
                        prevSecPos = sectionData.position
                    })
                })
        })
        .catch(function (error) {
            // console.log('error moving section')
            // console.log(error)
        })
}

export const addSection =
    (title, position, body, plainBody, projectId, isDefault) => (dispatch) => {
        dispatch(requestProject())

        // console.log('adding section')
        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                db.collection('projects')
                    .doc(projectId)
                    .collection('sections')
                    .add({
                        title: title,
                        encrypted: true,
                        body: obfuscate(body),
                        bodyText: obfuscate(plainBody),
                        position: position,
                        default: isDefault === undefined ? false : true,
                    })
                    .then(() => {
                        let projectsRef = db.collection('projects')
                        projectsRef
                            .doc(projectId)
                            .update({ sectionPosition: increment })
                            .then(() => {
                                // console.log('added section')
                                dispatch(updateProjectSuccess())
                            })
                            .catch((error) => {
                                dispatch(updateProjectFailure())
                            })
                    })
                    .catch((error) => {
                        // console.log('error adding section')
                        // console.log(error)
                    })
            })
            .catch(function (error) {
                dispatch(projectError())
            })
    }

export const renameNote = (projectId, noteId, title) => (dispatch) => {
    // console.log('renaming note')
    // console.log(noteId)

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('notes')
                .doc(noteId)
                .update({ title: title })
                .then(() => {
                    // console.log('note renamed')
                })
                .catch((error) => {
                    // console.log('error renaming note')
                    // console.log(error)
                })
        })
        .catch(function (error) {
            // console.log('error renaming note')
            // console.log(error)
        })
}

export const copyNote = (projectId, noteId) => (dispatch) => {
    // console.log('copying note')
    // console.log(noteId)

    let notePosition
    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .get()
                .then((doc) => {
                    notePosition = doc.data().notePosition
                })
        })

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('notes')
                .doc(noteId)
                .get()
                .then((doc) => {
                    // console.log(notePosition)
                    let copyData = doc.data()
                    copyData.title = copyData.title + ' (copy)'
                    // console.log(copyData)
                    dispatch(
                        addNote(
                            copyData.title,
                            notePosition + 1,
                            copyData.body,
                            copyData.bodyText,
                            projectId
                        )
                    )
                })
                .catch((error) => {
                    // console.log('error copying note')
                    // console.log(error)
                })
        })
        .catch(function (error) {
            // console.log('error copying note')
            // console.log(error)
        })
}
export const moveUpNote = (projectId, note) => (dispatch) => {
    // console.log('moving note up')
    // console.log(note.noteId)

    let startPos = note.position

    let prevSecId = null
    let prevSecPos = null
    let done = false

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('notes')
                .orderBy('position')
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        let sectionData = doc.data()
                        let curSecId = doc.id

                        if (sectionData.position === startPos && !done) {
                            // console.log('moving up')
                            let grab = prevSecId
                            if (!prevSecId) {
                                //at top do nothing
                                // console.log('at top, no move')
                                done = true
                            } else {
                                //move prev down one step
                                myFirebase
                                    .auth()
                                    .currentUser.getIdToken(true)
                                    .then(function (idToken) {
                                        // console.log('updating ' + grab)
                                        db.collection('projects')
                                            .doc(projectId)
                                            .collection('notes')
                                            .doc(grab)
                                            .update({
                                                position: startPos,
                                                role: 'prev',
                                            })
                                            .then(() => {
                                                // console.log(
                                                //     'prev position updated'
                                                // )

                                                myFirebase
                                                    .auth()
                                                    .currentUser.getIdToken(
                                                        true
                                                    )
                                                    .then(function (idToken) {
                                                        db.collection(
                                                            'projects'
                                                        )
                                                            .doc(projectId)
                                                            .collection('notes')
                                                            .doc(curSecId)
                                                            .update({
                                                                position:
                                                                    startPos -
                                                                    1,
                                                                role: 'target',
                                                            })
                                                            .then(() => {
                                                                // console.log(
                                                                //     'target position updated'
                                                                // )
                                                                done = true
                                                            })
                                                    })
                                            })
                                    })
                            }
                        }

                        prevSecId = doc.id
                        prevSecPos = sectionData.position
                    })
                })
        })
        .catch(function (error) {
            // console.log('error moving note')
            // console.log(error)
        })
}

export const moveDownNote = (projectId, note) => (dispatch) => {
    // console.log('moving note down')
    // console.log(note.noteId)

    let startPos = note.position

    let prevSecId = null
    let prevSecPos = null
    let done = false

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('notes')
                .orderBy('position', 'desc')
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        let sectionData = doc.data()
                        let curSecId = doc.id

                        if (sectionData.position === startPos && !done) {
                            // console.log('moving down')
                            let grab = prevSecId
                            if (!prevSecId) {
                                // console.log('at bottom, no move')
                                done = true
                            } else {
                                //move prev up one step
                                myFirebase
                                    .auth()
                                    .currentUser.getIdToken(true)
                                    .then(function (idToken) {
                                        // console.log('updating ' + grab)
                                        db.collection('projects')
                                            .doc(projectId)
                                            .collection('notes')
                                            .doc(grab)
                                            .update({
                                                position: startPos,
                                                role: 'prev',
                                            })
                                            .then(() => {
                                                // console.log(
                                                //     'prev position updated'
                                                // )

                                                myFirebase
                                                    .auth()
                                                    .currentUser.getIdToken(
                                                        true
                                                    )
                                                    .then(function (idToken) {
                                                        db.collection(
                                                            'projects'
                                                        )
                                                            .doc(projectId)
                                                            .collection('notes')
                                                            .doc(curSecId)
                                                            .update({
                                                                position:
                                                                    startPos +
                                                                    1,
                                                                role: 'target',
                                                            })
                                                            .then(() => {
                                                                // console.log(
                                                                //     'target position updated'
                                                                // )
                                                                done = true
                                                            })
                                                    })
                                            })
                                    })
                            }
                        }

                        prevSecId = doc.id
                        prevSecPos = sectionData.position
                    })
                })
        })
        .catch(function (error) {
            // console.log('error moving note')
            // console.log(error)
        })
}

export const deleteNote = (projectId, noteId) => (dispatch) => {
    // console.log('deleting note')
    // console.log(noteId)

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('notes')
                .doc(noteId)
                .delete()
                .then(() => {
                    // console.log('note deleted')
                })
                .catch((error) => {
                    // console.log('error deleting note')
                    // console.log(error)
                })
        })
        .catch(function (error) {
            // console.log('error deleting note')
            // console.log(error)
        })
}
export const addNote =
    (title, position, body, plainBody, projectId) => (dispatch) => {
        dispatch(requestProject())
        // console.log('adding note')
        // console.log(position)
        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                db.collection('projects')
                    .doc(projectId)
                    .collection('notes')
                    .add({
                        title: title,
                        encrypted: true,
                        body: obfuscate(body),
                        bodyText: obfuscate(plainBody),
                        position: position,
                    })
                    .then(() => {
                        let projectsRef = db.collection('projects')
                        projectsRef
                            .doc(projectId)
                            .update({ notePosition: increment })
                            .then(() => {
                                // console.log('added note')
                                dispatch(updateProjectSuccess())
                            })
                            .catch((error) => {
                                dispatch(updateProjectFailure())
                            })
                    })
                    .catch((error) => {
                        // console.log('error adding note')
                        // console.log(error)
                    })
            })
            .catch(function (error) {
                dispatch(projectError())
            })
    }

export const updateSection = (projectId, section) => (dispatch) => {
    dispatch(updateProject())

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('sections')
                .doc(section.sectionId)
                .update({
                    encrypted: true,
                    body: obfuscate(section.body),
                    bodyText: obfuscate(section.bodyText),
                })
                .then(() => {
                    // console.log('section updated')
                    dispatch(updateProjectSuccess())
                })
                .catch((error) => {
                    console.log('error updating section')
                    console.log(error)
                })
        })
        .catch(function (error) {
            dispatch(updateProjectFailure(error))
        })
}

export const updateNote = (projectId, note) => (dispatch) => {
    // console.log('updating note')
    // console.log(note)
    dispatch(updateProject())

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            db.collection('projects')
                .doc(projectId)
                .collection('notes')
                .doc(note.noteId)
                .update({
                    encrypted: true,
                    body: obfuscate(note.body),
                    bodyText: obfuscate(note.bodyText),
                })
                .then(() => {
                    // console.log('note updated')

                    dispatch(updateProjectSuccess())
                })
                .catch((error) => {
                    // console.log('error updating note')
                    // console.log(error)
                })
        })
        .catch(function (error) {
            dispatch(projectError())
            dispatch(updateProjectFailure())
        })
}

export const editProject = (docId, payload) => (dispatch) => {
    // console.log(docId)
    // console.log(payload)

    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            // console.log('saving primary')
            db.collection('projects')
                .doc(docId)
                .update({
                    ...(payload.title && { title: payload.title }),
                    ...(payload.description && {
                        description: payload.description,
                    }),
                    ...(payload.genres && { genres: payload.genres }),
                    ...(payload.tags && { tags: payload.tags }),
                })
            dispatch(updateProject())
        })
}

export const incrementViewCount = (publishedId) => (dispatch) => {
    // console.log('incrementing view counter ' + publishedId)
    var incrementPublishedViews = functions.httpsCallable(
        'incrementPublishedViews'
    )
    incrementPublishedViews({ publishedId })
}

const requestGetProject = () => {
    return {
        type: PROJECT_GET_REQUEST,
    }
}

const receiveGetProject = (project) => {
    return {
        type: PROJECT_GET_SUCCESS,
        project,
    }
}

export const projectGetComplete = () => {
    return {
        type: PROJECT_GET_COMPLETE,
    }
}

export const projectGetClear = () => {
    return {
        type: PROJECT_GET_CLEAR,
    }
}

const obfuscate = (text) => {
    const encrypted = sjcl.encrypt('password', text)
    return encrypted
}

const deobfuscate = (text) => {
    const decrypted = sjcl.decrypt('password', text)
    return decrypted
}

function compare(a, b) {
    let comparison = 0
    if (a.position > b.position) {
        comparison = 1
    } else if (a.position < b.position) {
        comparison = -1
    }
    return comparison
}

const sectionsGetSuccess = (sections) => {
    return {
        type: SECTIONS_GET_SUCCESS,
        sections,
    }
}
export const getSections = (projectId) => (dispatch) => {
    let sectionsRef = db
        .collection('projects')
        .doc(projectId)
        .collection('sections')

    sectionsRef.onSnapshot(function (querySnapshot) {
        let sections = []
        // console.log('got section snapshot')
        querySnapshot.forEach((section) => {
            const sectionData = section.data()
            // console.log(sectionData)
            sections.push({
                title: sectionData.title,
                id: section.id,
                body: sectionData.encrypted
                    ? deobfuscate(sectionData.body)
                    : sectionData.body,
                bodyText: sectionData.encrypted
                    ? deobfuscate(sectionData.bodyText)
                    : sectionData.bodyText,
                description: sectionData.description,
                default: sectionData.default,
                position: sectionData.position,
                published: sectionData.published,
                published_on: sectionData.published_on,
                published_id: sectionData.published_id,
                published_to_community: sectionData.published_to_community,
                community_id: sectionData.community_id,
                community_name: sectionData.community_name,
                community_published_id: sectionData.community_published_id,
                published_community_on: sectionData.published_community_on,
                sectionId: section.id,
            })
            // console.log(sections)
        })
        dispatch(sectionsGetSuccess(sections.sort(compare)))
    })
}

const notesGetSuccess = (notes) => {
    return {
        type: NOTES_GET_SUCCESS,
        notes,
    }
}
export const getNotes = (projectId) => (dispatch) => {
    let sectionsRef = db
        .collection('projects')
        .doc(projectId)
        .collection('notes')

    sectionsRef.onSnapshot(function (querySnapshot) {
        let notes = []

        querySnapshot.forEach((note) => {
            const noteData = note.data()
            notes.push({
                title: noteData.title,
                body: noteData.encrypted
                    ? deobfuscate(noteData.body)
                    : noteData.body,
                bodyText: noteData.encrypted
                    ? deobfuscate(noteData.bodyText)
                    : noteData.bodyText,
                position: noteData.position,
                noteId: note.id,
            })
        })
        dispatch(notesGetSuccess(notes.sort(compare)))
    })
}
export const getProject = (docId) => (dispatch) => {
    // console.log('getting project from firebase')
    dispatch(requestGetProject())
    let projectsRef = db.collection('projects')
    let sectionsRef = db
        .collection('projects')
        .doc(docId)
        .collection('sections')
    let notesRef = db.collection('projects').doc(docId).collection('notes')

    projectsRef.doc(docId).onSnapshot(function (doc) {
        var source = doc.metadata.hasPendingProjects ? 'Local' : 'Server'

        let uid = myFirebase.auth().currentUser?.uid
        if (doc.data()) {
            let likeUids = doc.data().likeUids || []
            let userLiked = likeUids.indexOf(uid) > -1
            let owned = doc.data().uid === uid
            let sections = []
            let notes = []

            // console.log(doc.data())
            sectionsRef.onSnapshot(function (querySnapshot) {
                querySnapshot.forEach((section) => {
                    const sectionData = section.data()
                    let index = sections.findIndex(
                        (s) => s.sectionId === section.id
                    )
                    if (index === -1) {
                        sections.push({
                            title: sectionData.title,
                            id: section.id,
                            body: sectionData.encrypted
                                ? deobfuscate(sectionData.body)
                                : sectionData.body,
                            bodyText: sectionData.encrypted
                                ? deobfuscate(sectionData.bodyText)
                                : sectionData.bodyText,
                            description: sectionData.description,
                            default: sectionData.default,
                            position: sectionData.position,
                            published: sectionData.published,
                            published_on: sectionData.published_on,
                            published_id: sectionData.published_id,
                            published_to_community:
                                sectionData.published_to_community,
                            community_id: sectionData.community_id,
                            community_name: sectionData.community_name,
                            community_published_id:
                                sectionData.community_published_id,
                            published_community_on:
                                sectionData.published_community_on,
                            sectionId: section.id,
                        })
                    }
                })

                notesRef.onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach((note) => {
                        const noteData = note.data()
                        let index = notes.findIndex((n) => n.noteId === note.id)
                        if (index === -1) {
                            notes.push({
                                title: noteData.title,
                                body: noteData.encrypted
                                    ? deobfuscate(noteData.body)
                                    : noteData.body,
                                bodyText: noteData.encrypted
                                    ? deobfuscate(noteData.bodyText)
                                    : noteData.bodyText,
                                position: noteData.position,
                                noteId: note.id,
                            })
                        }
                    })

                    if (doc.data().featuredId) {
                        getFeaturedUrls(doc.data().featuredId).then((urls) => {
                            // console.log(urls)

                            dispatch(
                                receiveGetProject({
                                    ...doc.data(),
                                    featuredUrls: urls,
                                    id: doc.id,
                                    source: source,
                                    userLiked: userLiked,
                                    owned: owned,
                                    sections: sections,
                                    notes: notes,
                                })
                            )
                        })
                    } else {
                        dispatch(
                            receiveGetProject({
                                ...doc.data(),
                                id: doc.id,
                                source: source,
                                userLiked: userLiked,
                                owned: owned,
                                sections: sections,
                                notes: notes,
                            })
                        )
                    }
                })
            })
        }
    })
}

const receiveListProject = (projectsList) => {
    return {
        type: PROJECT_LIST_SUCCESS,
        projectsList,
    }
}

const projectListError = () => {
    return {
        type: PROJECT_LIST_FAILURE,
    }
}

export const listPublishedWriting = (uid) => (dispatch) => {
    dispatch(listPublished())
    let projectsRef

    if (uid) {
        // console.log('listing published via uid')
        projectsRef = db
            .collection('published')
            .where('published', '==', true)
            .where('uid', '==', uid)
    } else {
        // console.log('listing all published')
        projectsRef = db.collection('published').where('published', '==', true)
    }
    // console.log(uid)

    projectsRef.onSnapshot(
        (snapshot) => {
            let response = []
            snapshot.forEach((doc) => {
                if (doc.data().ver >= 3 && !doc.data()?.removed)
                    response.push({ ...doc.data(), id: doc.id })
            })
            console.log('========================aaaaa=======================')
            console.log(response)

            dispatch(listPublishedSuccess(response))
        },
        (error) => {
            // console.log(error)
            dispatch(listPublishedFailure(error))
        }
    )
}

export const listPublishedFeatured = (uid) => (dispatch) => {
    dispatch(listPublished())

    // console.log('listing all featured project')
    let featuredRef = db
        .collection('published')
        .where('published', '==', true)
        .where('featured', '==', true)

    featuredRef.get().then(
        (snapshot) => {
            let response = []

            let likedRef = db
                .collection('published')
                .where('liked_count', '>=', 5)
            likedRef.get().then(
                (likedSnap) => {
                    likedSnap.forEach((doc) => {
                        if (doc.data().ver >= 3) {
                            // console.log(
                            //     response.filter((pub) => pub.id === doc.id)
                            // )
                            if (
                                response.filter((pub) => pub.id === doc.id)
                                    .length === 0
                            ) {
                                response.push({ ...doc.data(), id: doc.id })
                            }
                        }
                    })

                    snapshot.forEach((doc) => {
                        if (doc.data().ver >= 3)
                            if (!response.find((d) => d.id === doc.id))
                                response.push({ ...doc.data(), id: doc.id })
                    })

                    // console.log(response)
                    // console.log(response.filter((p) => !p?.removed))

                    dispatch(
                        listPublishedSuccess(
                            response.filter((p) => !p?.removed)
                        )
                    )
                },
                (error) => {
                    // console.log(error)
                }
            )
        },
        (error) => {
            // console.log(error)
            dispatch(listPublishedFailure(error))
        }
    )
}

export const listProjects = () => (dispatch) => {
    let projectsRef = db.collection('projects')
    if (myFirebase.auth().currentUser) {
        projectsRef
            .where('uid', '==', myFirebase.auth().currentUser.uid)
            .onSnapshot(
                (snapshot) => {
                    let response = []
                    snapshot.forEach((doc) => {
                        response.push({ ...doc.data(), id: doc.id })
                    })
                    dispatch(
                        receiveListProject(
                            response.filter((project) => project.title)
                        )
                    )
                },
                () => {
                    dispatch(projectListError())
                }
            )
    }
}

export const listProjectsById = (uid) => (dispatch) => {
    let projectsRef = db.collection('projects')
    projectsRef.where('uid', '==', uid).onSnapshot(
        (snapshot) => {
            let response = []
            snapshot.forEach((doc) => {
                response.push({ ...doc.data(), id: doc.id })
            })
            dispatch(
                receiveListProject(response.filter((project) => project.title))
            )
        },
        () => {
            dispatch(projectListError())
        }
    )
}

const requestProject = () => {
    return {
        type: PROJECT_REQUEST,
    }
}

const receiveProject = (project) => {
    return {
        type: PROJECT_SUCCESS,
        project,
    }
}

const projectError = () => {
    return {
        type: PROJECT_FAILURE,
    }
}
const projectComplete = () => {
    return {
        type: PROJECT_COMPLETE,
    }
}
export const saveProjectComplete = () => (dispatch) => {
    dispatch(projectComplete())
}

export const saveProject = (title, body, plainBody) => (dispatch) => {
    console.log('saving')
    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            console.log('saving 1')

            // console.log('got id ' + idToken)
            db.collection('projects')
                .add({
                    title: title,
                    body: '',
                    bodyText: '',
                    sectionPosition: 0,
                    notePosition: -1,
                    token: idToken,
                })
                .then((ref) => {
                    console.log('saving 2')
                    console.log(defaultTitle)
                    // console.log(ref.id)

                    dispatch(receiveProject({ id: ref.id }))

                    // db.collection('projects')
                    //     .doc(ref.id)
                    //     .collection('sections')
                    //     // .add({
                    //     //     title: defaultTitle,
                    //     //     body: '',
                    //     //     bodyText: '',
                    //     //     default: true,
                    //     //     position: 0,
                    //     // })
                    //     .then((ref2) => {
                    //         console.log('saving 3')
                    //         dispatch(receiveProject({ id: ref.id }))
                    //     })
                    //     .catch((error) => {
                    //         // console.log('error adding new project')
                    //         // console.log(error)
                    //     })
                })
                .catch((error) => {
                    // console.log('error adding new project')
                    // console.log(error)
                    dispatch(projectError())
                })
        })
        .catch(function (error) {
            // console.log('error adding new project')
            // console.log(error)
            dispatch(projectError())
        })
}
export const cacheFeaturedUrls = async (publishedId, featuredId) => {
    let publishedRef = db.collection('published').doc(publishedId)
    console.log('updated published featured')
    let urls = await getFeaturedUrls(featuredId)

    publishedRef.update({
        featuredUrlSmall: urls.featuredSmall,
        featuredUrlMedium: urls.featuredMedium,
        featuredUrlLarge: urls.featuredLarge,
    })
}

export const getFeaturedUrls = async (featuredId) => {
    // console.log(featuredId)

    var imagesRef = images.ref()

    const featuredSmall = await imagesRef
        .child('featured/resized/' + featuredId + '_120x120')
        .getDownloadURL()
    const featuredMedium = await imagesRef
        .child('featured/resized/' + featuredId + '_300x300')
        .getDownloadURL()
    const featuredLarge = await imagesRef
        .child('featured/resized/' + featuredId + '_600x600')
        .getDownloadURL()

    return { featuredSmall, featuredMedium, featuredLarge }
}

export const getPublicFeaturedUrls = async (featuredId) => {
    // console.log(featuredId)
    var imagesRef = images.ref()

    const featuredSmall = await imagesRef
        .child('featured/resized/' + featuredId + '_120x120')
        .getDownloadURL()
    const featuredMedium = await imagesRef
        .child('featured/resized/' + featuredId + '_300x300')
        .getDownloadURL()
    const featuredLarge = await imagesRef
        .child('featured/resized/' + featuredId + '_600x600')
        .getDownloadURL()

    return { featuredSmall, featuredMedium, featuredLarge }
}

export const sendPublishEmails = async (publishedId) => {
    // console.log('getting author profile for ' + publishedId)

    var sendPublishEmails = functions.httpsCallable('sendPublishEmails')

    return await sendPublishEmails({ publishedId: publishedId })
}
export const getAuthorProfile = async (projectId) => {
    // console.log('getting author profile for ' + projectId)

    var getAuthor = functions.httpsCallable('getAuthor')

    return await getAuthor({ projectId: projectId })
}

const uploadFeatured = () => {
    return {
        type: UPLOAD_FEATURED,
    }
}
const uploadFeaturedSuccess = () => {
    return {
        type: UPLOAD_FEATURED_SUCCESS,
    }
}
const uploadFeaturedFailure = () => {
    return {
        type: UPLOAD_FEATURED_FAILURE,
    }
}

export const saveFeaturedImage = (projectId, sectionId, file) => (dispatch) => {
    dispatch(uploadFeatured())

    const featuredId = uuid.v1()
    var imagesRef = images.ref()
    var avatarRef = imagesRef.child('featured/' + featuredId)
    var metadata = {
        contentType: file.type,
    }
    // console.log(projectId)
    avatarRef.put(file, metadata).then(function (snapshot) {
        // console.log('upload featured image ' + featuredId)

        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(async function (idToken) {
                let projectsRef = db.collection('projects').doc(projectId)
                // console.log('updated project featured')
                if (sectionId) {
                    // const urlInfo = await getFeaturedUrls(featuredId)
                    projectsRef
                        .collection('sections')
                        .doc(sectionId)
                        .update({ featuredId: featuredId })
                } else {
                    projectsRef.update({ featuredId: featuredId })
                }
                dispatch(uploadFeaturedSuccess())
            })
            .catch(function (error) {
                dispatch(uploadFeaturedFailure())
            })
    })
}

export const savePublishedFeaturedImage = (publishedId, file) => (dispatch) => {
    dispatch(uploadFeatured())

    const featuredId = uuid.v1()
    var imagesRef = images.ref()
    var avatarRef = imagesRef.child('featured/' + featuredId)
    var metadata = {
        contentType: file.type,
    }
    // console.log(publishedId)
    avatarRef.put(file, metadata).then(function (snapshot) {
        // console.log('upload featured image ' + featuredId)

        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                let publishedRef = db.collection('published').doc(publishedId)
                // console.log('updated published featured')
                publishedRef.update({ featuredId: featuredId })
                dispatch(uploadFeaturedSuccess())
            })
            .catch(function (error) {
                dispatch(uploadFeaturedFailure())
            })
    })
}
